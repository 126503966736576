<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-8">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.giveSample') }}</h1>
        <p>
          {{ $t('creator.max10') }}
        </p>
        
        <div>
          <b-spinner class="spinner-ws-photos-api" v-if="!loaded_contents"></b-spinner>
          <div class="container-file" v-if="loaded_contents">
            <b-img :src="require('@/assets/images/svg/cameraplus.svg')" class="img-camera"/>
            <p>{{$t('dashboard.chooseFile')}}</p>
          </div>
          
          <b-form-file 
            v-model="files_loaded" 
            accept=".jpeg, .jpg, .png, .mp4, .mov"
            class="my-2 custom-input" 
            @input="hasChangeFiles"
            :disabled="previews.length >= 10"
            v-if="loaded_contents"
            multiple
          >
          </b-form-file>
        </div>
        <div class="invalid-feedback-samples" v-if="show_file_bigger">{{$t('creator.file_big')}}</div>
        <strong class="text-danger col-12 mt-2 h4" v-if="previews.length >= 10">{{ $t('creator.limit10') }}</strong>

        <component :is="draggable" v-model="previews"> 
          <b-col v-for="(preview, index) in previews" :key="index" class="col-12 p-0 py-2 border-bottom d-flex justify-content-between box-previews-draggable">
            <div class="d-flex align-items-center">
              <b-icon icon="grip-vertical" class="grip-vertical-icon-preview"></b-icon>
              <b-avatar v-if="!isVideo(preview.preview)" :src="preview.preview" square class="avatar-preview-sample" icon="camera-video"></b-avatar>
              <video :src="preview.preview" class="avatar-preview-sample" v-else></video>
              <strong v-if="!preview.uuid">
                <span v-if="preview.name && preview.name.length > 0">
                  {{preview.name.slice(0, 25)}} <span v-if="preview.name.length > 25">...</span>
                </span>
                <span v-else class="text-muted">{{ $t('creator.noCaption') }}</span>
              </strong>
              <strong v-else class="text-muted">{{ $t('creator.upFile') }}</strong>
            </div>
            <b-button variant="flat-secondary" @click="deleteFromDraggable(preview)">
              <feather-icon icon="TrashIcon" size="20"></feather-icon>
            </b-button>
          </b-col>
        </component>
        <b-col class="col-12 d-flex justify-content-center flex-wrap">
          <b-button 
            variant="blue-button-next-step" 
            class="blue-button-next-step my-2" 
            :disabled="(previews.length === 0 || previews.length > 10) || loading_images"
            @click="save()"
          > 
            <div v-if="loading_images">
              <b-spinner class="spinner-waiting-service"></b-spinner>
              {{ $t('creator.upLoading') }}
            </div>
            <div v-else>
              {{ $t('creator.saveSeePreview') }}
            </div>
          </b-button>
          <p class="f-11">{{ $t('creator.max10') }}</p>
        </b-col>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BButton,
  BFormFile,
  BAvatar,
  BSpinner,
} from 'bootstrap-vue';
import proposals_services from '@/services/proposals';
import { isVideo } from '@/libs/utils/formats'
import { getUniqueIndex } from '@/libs/utils/others'
import { isUrl, getAssetUrl } from '@/libs/utils/urls'

export default {
  name: 'samples',
  components: {
    BImg,
    BRow,
    BCol,
    BButton,
    BFormFile,
    BAvatar,
    BSpinner,
  },
  data() {
    return {
      isVideo,
      files: [],
      previews: [],
      network_selected: null,
      steps: {},
      images_strings: [],
      loading_images: false,
      loaded_contents: false,
      files_loaded: null,
      show_file_bigger: false,
      contents: [],
      draggable: null,
      get_thumbnails: null, 
    }
  },
  created() {
    this.getData();
  },
  async mounted() {
    this.draggable = (await import('vuedraggable')).default;
    this.get_thumbnails = (await import('video-metadata-thumbnails')).getThumbnails;
  },
  computed: {
    has_proposal() {
      return this.$route.params.proposal
    }
  },
  methods: {
    hasChangeFiles() {
      this.show_file_bigger = false;
      if (this.files_loaded.length > 0) {
        this.files_loaded.forEach(file => {   
          const file_size = file.size / (1024 * 1024);
          if (file_size > 10) {
            file = null;
            this.show_file_bigger = true;
            return;
          }
          
          const urls_blobs = [];
          setTimeout(() => {
            const url_blob = URL.createObjectURL(file);
            const unique_id = getUniqueIndex();
            if (!file.type.includes('video')) {
              urls_blobs.push({preview: url_blob, name: file.name, id: unique_id});
            } else {
              this.previews.push({preview: 'is_video', name: file.name, id: unique_id});
              const thumbnail = this.get_thumbnails(url_blob, {
                quality: 0.6
              });
              thumbnail.then((value) => {
                const url_blob_thubnail = URL.createObjectURL(value[0].blob);
                this.previews.map((f) => {
                  if (f.id === unique_id) f.preview = url_blob_thubnail
                });
              });
            }
            if (this.previews.length < 10) {
              this.previews = this.previews.concat(urls_blobs);
              this.files.push(file)
            }
          }, 200)
        });
      }
    },
    getData() {
      this.steps = this.$route.params.proposal;

      this.steps.images.forEach((image) => {
        this.previews.push({sort: image.sort,preview: isUrl(image.media) ? image.media : getAssetUrl(image.media), uuid: image.uuid})
      });
      this.previews.sort((a, b) => a.sort - b.sort);
      this.loaded_contents = true
    },
    deleteFromDraggable(preview) {
      if (this.steps.slug && preview.uuid) {
        proposals_services.deleteElement(this.steps.uuid, preview.uuid, 'image');
      }
      this.files = this.files.filter((item) => item.name !== preview.name);
      this.previews = this.previews.filter((item) => item.preview !== preview.preview);
      this.images_strings = this.images_strings.filter((item) => item.preview !== preview.preview);
    },
    getOrderFormat() {
      const ordered = [];
      this.previews.map((item, index) => {
        ordered.push({uuid: item.uuid, name: item.preview.includes('blob:') ? item.name : item.preview, order: index});
      });
      return ordered;
    },
    save() {
      if (this.files.length > 0) {
        this.loading_images = true;
        const form_data = new FormData()
        this.files.forEach((file) => {
          form_data.append('images_file', file);
        });
        form_data.append('order', JSON.stringify(this.getOrderFormat()));
        proposals_services.uploadImagesProposal(this.steps.uuid, form_data).then((response) => {
          this.$route.params.proposal = response
          if (this.has_proposal) this.$emit('update_proposal', response)
          else this.redirectPreview(response.slug)
          this.loading_images = false;
        });
      } else if (this.has_proposal) {
        proposals_services.orderImagesProposal(this.steps.uuid, {images: this.getOrderFormat()}).then((response) => {
          this.$route.params.proposal = response;
          this.$emit('update_proposal', response)
        })
      } else this.redirectPreview(this.steps.slug)
    },
    redirectPreview(slug) {
      this.$router.push({name: 'view_proposal', params: {slug}});
      this.$root.$emit('quit_navbar_white');
      localStorage.removeItem('steps');
    }
  },
}
</script>
<style>
.container-file {
  top: 54px;
  position: relative;
  color: rgba(0,0,0,.45);
  font-size: 12px;
  cursor: pointer;
}
.custom-input .custom-file-label::after {
  display: none;
}
.img-camera {
  width: 20px;
}
.custom-input .custom-file-label {
    border: 1px dashed #3483fa !important;
    cursor: pointer;
    color: transparent;
    position: absolute;
    height: 104px !important;
    right: 40px;
    left: 35px;
    top: -58px;
    background: transparent !important;
}
.custom-input .custom-file-label:hover {
    border: 2px solid #3483fa !important;
    cursor: pointer;
}
.invalid-feedback-samples {
  font-size: 0.857rem !important;
  color: #ea5455 !important;
}
.f-11 {
  font-size: 13px;
  color: #b0b0b0;
}
.spinner-waiting-service {
  width: 18px;
  height: 18px;
}
.spinner-ws-photos-api {
  height: 25px;
  width: 25px;
  color: #3483fa;
  z-index: 30;
}
.avatar-preview-sample {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 1em !important;
  margin: 0 1em 0 0;
}
.grip-vertical-icon-preview {
  width: 25px;
  height: 25px; 
  color: #b0b0b0; 
}
.box-previews-draggable {
  cursor:grab;
}
</style>